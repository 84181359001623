import { ref } from 'vue'
import { projectAuth, projectFirestore, timestamp } from '@/firebase/config'

const error = ref(null)
const isPending = ref(false)

const createUser = async (email, password) => {
  error.value = null
  isPending.value = true

  try {
    const res = await projectAuth.createUserWithEmailAndPassword(email, password)
    if (!res) {
      throw new Error('Could not complete signup')
    }

    error.value = null
    isPending.value = false
    return res
  }
  catch(err) {
    console.log(err.message)
    error.value = err.message
    isPending.value = false
  }
}

const createAccount = async (user) => {
  error.value = null
  isPending.value = true

  console.log('createAccount user', user)

  try {
    await projectAuth.currentUser.updateProfile({
      displayName: user.displayName
    })

    const res = await projectFirestore.collection('accounts').doc(user.id).set({
      // ...user, doesnt work, dont try it!
      email: user.email,
      displayName: user.displayName,
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.company,
      photoUrl: '',
      // description: '',
      // views: [],
      created: timestamp()
    })

    await projectFirestore.collection('accounts').doc(user.id).collection('notifications').add({
      type: 'welcome',
      message: 'Welcome to Marfarfa!',
      seen: false,
      created: timestamp()
    })

    await projectFirestore.collection('mail').add({
      to: user.email,
      templateId: 'd-509a943138e345aa8f203ce973b88f59', // SWAGIBLE:EMAILCONF
      dynamic_template_data: {
        confirm_url: process.env.VUE_APP_BASE_URL,
        first_name: user.firstName,
        last_name: user.lastName,
      }
    })

    await projectFirestore.collection('mail').add({
      to: 'support@swagible.com',
      templateId: 'd-c1d1e313ab6b42e5813d5df880d8436d', // SWAGIBLE:ADMINAHSIGNUP
      dynamic_template_data: {
        accountholder_first_name: user.firstName,
        accountholder_last_name: user.lastName,
        accountholder_email: user.email,
        accountholder_company: user.company,
        dashboard_url: `${process.env.VUE_APP_BASE_URL}/dashboard`
      }
    })

    // var dt = new Date();
    // await projectFirestore.collection('mailTriggers').doc(user.id).set({
    //   email: user.email,
    //   noCampaignTrigger: dt.setHours(dt.getHours() + 24),
    //   firstName: user.firstName
    // })
    
    isPending.value = false
    return res
  }
  catch(err) {
    console.log(err.message)
    error.value = 'could not add profile'
    isPending.value = false
  }
}

const updateProfile = async (id, payload) => {
  error.value = null
  isPending.value = true
  
  try {
    const res = await projectFirestore.collection('accounts').doc(id)
      .update(payload)
      // .set(payload, { merge: true })
    error.value = null
    isPending.value = false
    return res
  }
  catch(err) {
    console.log(err.message)
    error.value = err.message
    isPending.value = false
  }
}

const useSignup = () => {
  return { error, createUser, createAccount, updateProfile, isPending }
}

export default useSignup